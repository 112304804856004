
export const environment = {
   production: true,
   cognito: {
    userPoolId: "",
    userPoolWebClientId: "",
    region: "",
  identityPoolId: "",
  },
  auth_domain: "qa.matterworks.ai",
  auth_client_id: "wjvFneejP49x88bj7A5s34YhYSLEgjv7",
  auth_audience: "matterworks.ai",
  auth_issuer: "https://dev-dmxte2bgqavqeo5m.us.auth0.com/",
  ddbTableName: 'LoginTrail',
  timeout: "82800",
  cognito_idp_endpoint: "",
  cognito_identity_endpoint:"",
  sts_endpoint: "",
  dynamodb_endpoint:"",
  s3_endpoint: "",
  users_guide: "undefined",
  version: "undefined",
  revision_date: "undefined",
  gitbook_privatekey: "undefined"
};
